import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Title from '../Title';
import { Button, Col, Container, Row } from 'react-bootstrap';

const About = () => {
    const { aboutImage } = useStaticQuery(getAbout);

    return (
        <Container fluid className="align-items-center pt-6">
            <Title title="over" subtitle="mij" />
            <Row data-aos="fade-up" className="justify-content-center d-flex">
                <Col lg={3} md={4} sm={8}>
                    <Img className="img" fluid={aboutImage.childImageSharp.fluid} alt="Stefani Peters" />
                </Col>
                <Col lg={6} md={4} sm={8} className="d-flex justify-content-center align-items-center">
                    <Row>
                    <p className="p-3">
                        Hoi en welkom op mijn blog. Ik ben Stefani Peters, 30 jaar en woon in Utrecht samen met mijn vriend en lieve katten Loetje en Fritz.
                        In het dagelijkse leven ben ik software engineer bij Finaps. Mijn grootste hobby is met eten bezig zijn ...
                    </p>
                    <Container fluid className="justify-content-center d-flex">
                    <Button href="/about" className="btn-blue-border">Lees meer</Button>
                    </Container>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default About;

const getAbout = graphql`
query aboutImage {
    aboutImage: file(relativePath: {eq: "Stefani-Peters.png"}) {
      childImageSharp {
        fluid(maxWidth: 300, toFormat: WEBP, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`