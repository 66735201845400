import React from "react";
import { graphql } from "gatsby";
import PageWrapper from "../components/Layout/PageWrapper";
import About from "../components/Home/About";
import StyledHero from "../components/StyledHero";
import NewestWeekmenus from '../components/Home/NewestWeekmenus';
// import Categories from "../components/Home/Categories";
import NewestRecipes from "../components/Home/NewestRecipes";
import { Container } from "react-bootstrap";
import SVG from 'react-inlinesvg';
// import InstagramPosts from "../components/Home/InstagramPosts";
import SEO from "../components/seo";

const WhiteLogo = () => <SVG src={require("../assets/images/Logo.svg")} preProcessor={code => code.replace(/fill=".*?"/g, 'fill="white"')}/>;

export default ({ data }) => (
  <PageWrapper>
    <SEO title="Stefani Peters"/>
    <StyledHero home="true" img={data.defaultBcg.childImageSharp.fluid}>
      <Container id="LogoContainer" fluid>
        <Container fluid className="d-flex justify-content-center">
        <WhiteLogo/>
        </Container>
        <h4 className="h4-white text-center">Recepten | Weekmenu's | Blogs</h4>
      </Container>
    </StyledHero>
    <NewestRecipes />
    <NewestWeekmenus />
    <About />
    {/* <InstagramPosts /> */}
    {/* <Categories /> */}
  </PageWrapper>
)

export const query = graphql`
  {
    defaultBcg: file(relativePath: { eq: "P4120676_Gradient.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logo: file(relativePath: { eq: "LogoLarge_White.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
