import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Title from '../Title';
import PostCard from './PostCard';
import { Button, CardColumns, Col, Container, Row } from "react-bootstrap";

const NewestRecipes = () => {
  const { newestPosts } = useStaticQuery(getPosts);
  return (
    <Container fluid className="pt-6">
      <Title title="nieuwste" subtitle="recepten" />
      <Row className="justify-content-center d-flex" data-aos="fade-up">
        <Col xl={12}>
          <Row className="justify-content-center d-flex">
            {newestPosts.nodes.map((node) => {
              return (
                <CardColumns className="justify-content-center d-flex pb-3" key={node.id}>
                  <PostCard post={node} page={'recipes'} body={true} />
                </CardColumns>
              )
            })}
          </Row>
        </Col>
        <Col xl={12} className="justify-content-center d-flex">
          <Button href="recipes" className="btn-blue-border">Naar alle recepten</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default NewestRecipes;

const getPosts = graphql`
{
  newestPosts: allContentfulRecipe(limit: 4, sort: {fields: date, order: DESC}) {
    nodes {
      id
      slug
      title
      featuredImage {
        fluid(quality: 100, maxHeight: 750, toFormat: WEBP) {
          src
        }
        title
      }
    }
  }
}`