import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Title from '../Title';
import PostCard from './PostCard';
import { Button, CardColumns, Col, Container, Row } from "react-bootstrap"

const NewestWeekmenus = () => {
  const { newestPosts } = useStaticQuery(getPosts);
  return (
    <Container fluid className="pt-6">
      <Title title="nieuwste" subtitle="weekmenu's" />
      <Row className="justify-content-center d-flex" data-aos="fade-up">
      <Col xl={12}>
          <Row className="justify-content-center d-flex">
        {newestPosts.nodes.map((node) => {
          return (
            <CardColumns className="justify-content-center d-flex pb-3" key={node.id}>
              <PostCard post={node} page={'weekmenu'} body={false} />
            </CardColumns>
          )
        })}
        </Row>
        </Col>
        <Col xl={12} className="justify-content-center d-flex">
          <Button href="weekmenu" className="btn-blue-border">Naar alle weekmenu's</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default NewestWeekmenus;

const getPosts = graphql`
{
  newestPosts: allContentfulWeekmenu(limit: 3, sort: {fields: date, order: DESC}) {
    nodes {
      id
      slug
      title
      featuredImage {
        fluid(quality: 100, maxHeight: 750, toFormat: WEBP) {
          src
        }
        title
      }
    }
  }
}`