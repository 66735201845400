import React from 'react';
import { Card } from "react-bootstrap"

const PostCard = ({ post, page, body }) => {
    const { title, slug, featuredImage } = post;
    return (
        <Card className="text-center card-borderless px-3" style={{ width: '20rem' }}>
            <Card.Link href={`${page}/${slug}`} className="a-home">
                <Card.Img className="img-fluid" style={{ maxHeight: "450px" }} variant="top" src={featuredImage.fluid.src} alt={featuredImage.title}/>
                {body && (
                    <Card.Body className="post-card-body justify-content-center">
                        <Card.Title className="h6 p-0 m-0">{title}</Card.Title>
                    </Card.Body>
                )}
            </Card.Link>
        </Card>
    )
}

export default PostCard;
